import React, { useState } from 'react';
import { auth, db } from '../firebase-config';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc, collection, getDocs, writeBatch } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';

const SignupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #00a99d;
`;

const FormContainer = styled.div`
  background: white;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #00a99d;
  border-radius: 4px;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const CheckboxLabel = styled.label`
  font-size: 0.875rem;
  color: #333;
`;

const TermsText = styled.p`
  font-size: 0.75rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
  a {
    color: #007bff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Button = styled.button`
  padding: 0.75rem;
  font-size: 1rem;
  color: white;
  background-color: #00a99d;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const NavLink = styled(Link)`
  font-size: 1rem;
  color: ${props => (props.active ? '#007bff' : '#333')};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  border-bottom: ${props => (props.active ? '2px solid #007bff' : 'none')};
`;

const Signup = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const [receiveEmails, setReceiveEmails] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Add default role "user"
      await setDoc(doc(db, 'users', user.uid), {
        fullName,
        email: user.email,
        role: 'user',
        keepSignedIn,
        receiveEmails
      });

      // Copy all existing coupons to the userCoupons collection for this user
      const couponsSnapshot = await getDocs(collection(db, 'coupons'));
      const batch = writeBatch(db);
      couponsSnapshot.forEach((couponDoc) => {
        const userCouponRef = doc(collection(db, 'users', user.uid, 'userCoupons'));
        batch.set(userCouponRef, {
          ...couponDoc.data(),
          couponId: couponDoc.id
        });
      });
      await batch.commit();

      alert('Welcome!');
      navigate('/view-coupons'); // Redirect to coupons page after signup
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <SignupContainer>
      <FormContainer>
        <LinkContainer>
          <NavLink to="/login">Login</NavLink>
          <NavLink to="/signup" active>Register</NavLink>
        </LinkContainer>
        <Title>Register</Title>
        <Form onSubmit={handleSignup}>
          <Input
            type="text"
            placeholder="Full name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <CheckboxContainer>
            <Checkbox
              type="checkbox"
              checked={keepSignedIn}
              onChange={(e) => setKeepSignedIn(e.target.checked)}
            />
            <CheckboxLabel>Keep me signed in</CheckboxLabel>
          </CheckboxContainer>
          <CheckboxContainer>
            <Checkbox
              type="checkbox"
              checked={receiveEmails}
              onChange={(e) => setReceiveEmails(e.target.checked)}
            />
            <CheckboxLabel>
              Yes, I want to save money by receiving personalized emails with awesome deals.
            </CheckboxLabel>
          </CheckboxContainer>
          <TermsText>
            By clicking below, I agree to <a href="#">Terms of Use</a> and have read the <a href="#">Privacy Statement</a>.
          </TermsText>
          <Button type="submit">Register</Button>
        </Form>
      </FormContainer>
    </SignupContainer>
  );
};

export default Signup;


