// src/firebase-config.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyBe2Gr755PkB6AFShs6MAKy36qHSUfcPiQ",
  authDomain: "casa-costa-coupon.firebaseapp.com",
  databaseURL: "https://casa-costa-coupon-default-rtdb.firebaseio.com",
  projectId: "casa-costa-coupon",
  storageBucket: "casa-costa-coupon.appspot.com",
  messagingSenderId: "567023537245",
  appId: "1:567023537245:web:bac60396c31f5b4002d1b4",
  measurementId: "G-693LR4YJWB"
  }; // firebise sur kaada.mohamed1@gmail.com

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const storage = getStorage(app);
  
  export { db, auth, storage };
