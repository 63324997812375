import React, { useState } from 'react';
import { auth, db } from '../firebase-config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import styled from 'styled-components';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #00a99d;
`;

const FormContainer = styled.div`
  background: white;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #00a99d;
  border-radius: 4px;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const ForgotPasswordLink = styled.a`
  text-align: right;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #007bff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const CheckboxLabel = styled.label`
  font-size: 0.875rem;
  color: #333;
`;

const TermsText = styled.p`
  font-size: 0.75rem;
  color: #333;
  margin-bottom: 1rem;
  a {
    color: #007bff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Button = styled.button`
  padding: 0.75rem;
  font-size: 1rem;
  color: white;
  background-color: #00a99d;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Vérifiez le rôle de l'utilisateur après la connexion
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const role = userDoc.data().role;
        if (role === 'admin') {
          navigate('/create-coupon'); // Redirigez les administrateurs vers la page de création de coupons
        } else {
          navigate('/view-coupons'); // Redirigez les utilisateurs normaux vers la page des coupons disponibles
        }
      } else {
        alert("No user document found!");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <LoginContainer>
      <FormContainer>
        <Title>Login</Title>
        <Form onSubmit={handleLogin}>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <ForgotPasswordLink href="#">Forgot your password?</ForgotPasswordLink>
          <CheckboxContainer>
            <Checkbox type="checkbox" id="keepSignedIn" />
            <CheckboxLabel htmlFor="keepSignedIn">Keep me signed</CheckboxLabel>
          </CheckboxContainer>
          <TermsText>
            By clicking below, I agree to <a href="#">Terms of Use</a> and have read the <a href="#">Privacy Statement</a>.
          </TermsText>
          <Button type="submit">Login</Button>
        </Form>
      </FormContainer>
    </LoginContainer>
  );
};

export default Login;
