// src/components/ManageCoupons.js
import React, { useEffect, useState } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';

const ManageCoupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentCoupon, setCurrentCoupon] = useState({});

  useEffect(() => {
    const fetchCoupons = async () => {
      const couponCollection = await getDocs(collection(db, 'coupons'));
      setCoupons(couponCollection.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };
    fetchCoupons();
  }, []);

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, 'coupons', id));
    setCoupons(coupons.filter(coupon => coupon.id !== id));
  };

  const handleEdit = (coupon) => {
    setEditing(true);
    setCurrentCoupon(coupon);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    await updateDoc(doc(db, 'coupons', currentCoupon.id), currentCoupon);
    setCoupons(coupons.map(coupon => (coupon.id === currentCoupon.id ? currentCoupon : coupon)));
    setEditing(false);
    setCurrentCoupon({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentCoupon({ ...currentCoupon, [name]: value });
  };

  return (
    <div>
      <h1>Manage Coupons</h1>
      {editing ? (
        <form onSubmit={handleUpdate}>
          <input
            type="text"
            name="code"
            placeholder="Code"
            value={currentCoupon.code}
            onChange={handleChange}
          />
          <input
            type="text"
            name="description"
            placeholder="Description"
            value={currentCoupon.description}
            onChange={handleChange}
          />
          <input
            type="date"
            name="expirationDate"
            placeholder="Expiration Date"
            value={currentCoupon.expirationDate}
            onChange={handleChange}
          />
          <button type="submit">Update</button>
        </form>
      ) : (
        <ul>
          {coupons.map(coupon => (
            <li key={coupon.id}>
              <h2>{coupon.code}</h2>
              <p>{coupon.description}</p>
              <p>Expires on: {coupon.expirationDate}</p>
              <button onClick={() => handleEdit(coupon)}>Edit</button>
              <button onClick={() => handleDelete(coupon.id)}>Delete</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ManageCoupons;