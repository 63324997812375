// src/components/Navbar.js
import React from 'react';
import { auth } from '../firebase-config';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await signOut(auth);
    navigate('/login'); // Rediriger vers la page de connexion après la déconnexion
  };

  return (
    <nav className="flex justify-between items-center p-4 bg-gray-800 text-white">
      <h1 className="text-2xl">Choose Your Coupon</h1>
      <button onClick={handleSignOut} className="bg-red-500 px-4 py-2 rounded">
        Sign Out
      </button>
    </nav>
  );
};

export default Navbar;