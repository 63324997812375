import React, { useState, useEffect } from 'react';
import { db, auth, storage } from '../firebase-config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, getDocs, doc, getDoc, writeBatch } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Notification from './Notification';

const CreateCoupon = () => {
  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [role, setRole] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [file, setFile] = useState(null); // State for file
  const [notification, setNotification] = useState(''); // State for notifications
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserRole = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setRole(userDoc.data().role);
          setUserInfo(userDoc.data());
        } else {
          alert("No user document found!");
        }
      }
    };

    fetchUserRole();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (role !== 'admin') {
      setNotification("Access denied: You are not an administrator");
      navigate('/');
      return;
    }

    try {
      let imageUrl = '';
      if (file) {
        const storageRef = ref(storage, `coupons/${file.name}`);
        await uploadBytes(storageRef, file);
        imageUrl = await getDownloadURL(storageRef);
      }

      // Create the coupon in the 'coupons' collection
      const couponDoc = await addDoc(collection(db, 'coupons'), {
        code,
        description,
        expirationDate,
        imageUrl
      });

      // Get all users and add the coupon to their 'userCoupons' collection
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const batch = writeBatch(db);
      usersSnapshot.forEach((userDoc) => {
        const userCouponRef = doc(collection(db, 'users', userDoc.id, 'userCoupons'));
        batch.set(userCouponRef, {
          code,
          description,
          expirationDate,
          couponId: couponDoc.id,
          imageUrl
        });
      });
      await batch.commit();

      setNotification('Coupon created successfully');
      setCode('');
      setDescription('');
      setExpirationDate('');
      setFile(null); // Reset the file
    } catch (error) {
      setNotification(error.message);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-[#00a99d] to-[#00a99d]">
        <h1 className="text-4xl font-bold text-white mb-8">Create Coupon</h1>
        <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
          <input
            type="text"
            placeholder="Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className="w-full p-2 mb-4 border rounded"
          />
          <input
            type="text"
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 mb-4 border rounded"
          />
          <input
            type="date"
            placeholder="Expiration Date"
            value={expirationDate}
            onChange={(e) => setExpirationDate(e.target.value)}
            className="w-full p-2 mb-4 border rounded"
          />
          <input
            type="file"
            onChange={(e) => setFile(e.target.files[0])} // Handle file selection
            className="w-full p-2 mb-4 border rounded"
          />
          <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded">Create</button>
        </form>
        {notification && <Notification message={notification} />}
      </div>
    </div>
  );
};

export default CreateCoupon;


