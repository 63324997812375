import React from 'react';
import { Link } from 'react-router-dom';
import logo1 from '../logo1.png'; // Update the path to your logo images
import logo2 from '../logo2.png'; // Update the path to your logo images

const Home = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-[#00a99d] to-[#00a99d]">
      <h1 className="text-6xl font-bold text-white mb-8">Casa Costa VIP</h1>
      <div className="flex space-x-8 mb-8">
        <img src={logo1} alt="Logo 1" className="h-32" />
        <img src={logo2} alt="Logo 2" className="h-32" />
      </div>
      <div className="mt-8">
        <Link to="/signup" className="text-2xl text-black-200 hover:underline">Sign Up</Link>
        <span className="mx-4 text-2xl text-white">|</span>
        <Link to="/login" className="text-2xl text-black-200 hover:underline">Login</Link>
      </div>
    </div>
  );
};

export default Home;
