import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase-config';
import { collection, getDocs, doc, deleteDoc, addDoc } from 'firebase/firestore';
import Navbar from './Navbar';
import Notification from './Notification';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const ViewCouponsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #00a99d;
`;

const CouponCard = styled(motion.li)`
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  width: 100%;
  max-width: 400px;
`;

const CouponImage = styled.img`
  margin-top: 8px;
  width: 100%;
  height: auto;
  border-radius: 4px;
`;

const UseCouponButton = styled.button`
  margin-top: 8px;
  background-color: #00a99d;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const ViewCoupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [notification, setNotification] = useState(''); // New state for notifications

  useEffect(() => {
    const fetchUserCoupons = async () => {
      const user = auth.currentUser;
      if (user) {
        const userCouponsCollection = collection(db, 'users', user.uid, 'userCoupons');
        const userCouponsSnapshot = await getDocs(userCouponsCollection);
        setCoupons(userCouponsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      }
    };
    fetchUserCoupons();
  }, []);

  const handleUseCoupon = async (coupon) => {
    try {
      const user = auth.currentUser;
      if (user) {
        // Move the coupon to 'usedCoupons' collection
        await addDoc(collection(db, 'users', user.uid, 'usedCoupons'), {
          ...coupon,
          usedBy: auth.currentUser.email,
          usedAt: new Date().toISOString()
        });
        // Delete the coupon from 'userCoupons' collection
        await deleteDoc(doc(db, 'users', user.uid, 'userCoupons', coupon.id));
        setCoupons(coupons.filter(c => c.id !== coupon.id));
        setNotification(`Coupon with ID ${coupon.id} has been used. Show this ID to the server.`);
      }
    } catch (error) {
      setNotification(error.message);
    }
  };

  return (
    <div>
      <Navbar />
      <ViewCouponsContainer>
        <h1 className="text-4xl font-bold mb-4 text-white">Available Coupons</h1>
        <ul className="w-full max-w-md">
          {coupons.map(coupon => (
            <CouponCard
              key={coupon.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-xl font-semibold">{coupon.code}</h2>
              <p className="text-gray-700">{coupon.description}</p>
              <p className="text-gray-500">Expires on: {coupon.expirationDate}</p>
              {coupon.imageUrl && <CouponImage src={coupon.imageUrl} alt={coupon.description} />}
              <UseCouponButton onClick={() => handleUseCoupon(coupon)}>
                Use Coupon
              </UseCouponButton>
            </CouponCard>
          ))}
        </ul>
        {notification && <Notification message={notification} />}
      </ViewCouponsContainer>
    </div>
  );
};

export default ViewCoupons;
