// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Signup from './components/Signup';
import Login from './components/Login';
import CreateCoupon from './components/CreateCoupon';
import ViewCoupons from './components/ViewCoupons';
import ManageCoupons from './components/ManageCoupons';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/create-coupon" element={<CreateCoupon />} />
        <Route path="/view-coupons" element={<ViewCoupons />} />
        <Route path="/manage-coupons" element={<ManageCoupons />} />
      </Routes>
    </Router>
  );
};

export default App;